import { FC } from 'react';
import { Link } from '@lidofinance/lido-ui';
import { LidoLogo } from '@lidofinance/lido-logo';
import {
  FooterStyle,
  FooterDividerStyle,
  FooterLogoStyle,
  FooterGroupStyle,
  FooterTitleStyle,
  FooterItemStyle,
} from './footerStyles';

const Footer= () => (
  <FooterStyle size="full" forwardedAs="footer"  >
    <FooterDividerStyle />

    {/* <FooterLogoStyle>
      <LidoLogo />
    </FooterLogoStyle> */}

    <FooterGroupStyle style={{marginLeft:'12.5vw'}}>
        <div>
      <FooterTitleStyle>Resources</FooterTitleStyle>
      {/* <FooterItemStyle>
        <Link href="/#/ftm">Deposit FTM</Link>
      </FooterItemStyle> */}
      <FooterItemStyle>
        <Link href="/#/usd">Deposit USD</Link>
      </FooterItemStyle>
      {/* <FooterItemStyle>
        <Link href="https://medium.com/@scythe.finance/introducing-scythe-finance-a-new-yield-farming-experience-99d180ff884f">
          introduction
        </Link>
      </FooterItemStyle> */}
      <FooterItemStyle>
        <Link href="https://docs.hellhound.dog">Documentation</Link>
      </FooterItemStyle>
      <FooterItemStyle>
        <Link href="/#/terms">Terms of Use</Link>
      </FooterItemStyle>
      {/* <FooterItemStyle>
        <Link href=" https://medium.com/@scythe.finance/scythe-distribution-details-37fa3264dc71">Tokenomics</Link>
      </FooterItemStyle> */}
      {/* <FooterItemStyle>
        <Link href="https://lido.fi/static/LIDO_press_kit.zip">Press Kit</Link>
      </FooterItemStyle> */}
      </div>
    </FooterGroupStyle>

    <FooterGroupStyle>
      <FooterTitleStyle>Community</FooterTitleStyle>
      <FooterItemStyle>
        <Link href="https://twitter.com/NetherworldDAO">Twitter</Link>
      </FooterItemStyle>
      {/* <FooterItemStyle>
        <Link href="https://t.me/scythefinance">Telegram</Link>
      </FooterItemStyle> */}
      <FooterItemStyle>
        <Link href="https://docs.hellhound.dog">Documentation</Link>
      </FooterItemStyle>
      <FooterItemStyle>
        <Link href="discord.gg/PGdtwjqjuJ">Discord</Link>
      </FooterItemStyle>
      <FooterItemStyle>
        <Link href="https://github.com/Hellhound-Doge">GitHub</Link>
      </FooterItemStyle>
      {/* <FooterItemStyle>
        <Link href="https://medium.com/@scythe.finance">Medium</Link>
      </FooterItemStyle> */}
    </FooterGroupStyle>

    <FooterGroupStyle>
      <FooterTitleStyle>Contacts</FooterTitleStyle>
      <FooterItemStyle>
        <Link href="mailto:mypeppermintdev@gmail.com">Email</Link>
      </FooterItemStyle>
      <FooterItemStyle>
        <Link href="https://twitter.com/NetherworldDAO">@NetherworldDAO</Link>
      </FooterItemStyle>
    </FooterGroupStyle>
  </FooterStyle>
);

export default Footer;