import { createRef, useEffect, useState } from 'react';
import { H2,Text,Block,InputGroup,SelectIcon,Option,Input,Button,DataTable,DataTableRow,Accordion,StackItem,HStack,Select,ArrowRight } from '@lidofinance/lido-ui';
import { useEthers,useCall,useCalls,useTokenBalance,useEtherBalance, DAppProvider } from "@usedapp/core";
import {StatHolder,StatLabel,StatValueHolder} from "./stakingStyles.js";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { abis,addresses } from "@my-app/contracts";
import StakeIcon from './stakeIcons';
import { formatEther, parseEther,parseUnits,formatUnits } from '@ethersproject/units'
import {calcAPY,calcAPR,calcDjedEarnings} from '../../lib/lib.js';
// arrow icon



const StableInfo = (props) => {
    
    const settings = props.settings;
    const config = settings;

    window.dogedollarconfig = config;
    const { library,account } = useEthers();
    const [lpAPR,setLpAPR] = useState(0);

    const params = new URLSearchParams(window.location.search);

    // console.log('dogedollar replaced?: ',config.dogedollar.userSpec)

    const Djed = new Contract(config.djed.address,config.djed.abi);
    const DogeDollar = new Contract(config.dogedollar.address,config.dogedollar.abi);
    const oracle = new Contract(config.oracle.address,config.oracle.abi);

    useEffect(()=>{
        (async ()=>{
            const Djed = new ethers.Contract(config.djed.address,config.djed.abi,window.provider);
            const Oracle = new ethers.Contract(config.oracle.address,config.oracle.abi,window.provider);
            const djedEarnings = await calcDjedEarnings(Djed,Oracle,window.provider);
            const deploymentDateTime = (new Date('July-15-2023')-0)/1000;
            const currentDateTime = (new Date()-0)/1000;
            var djedAPR = calcAPR(djedEarnings,currentDateTime-deploymentDateTime);
            // limit APR to between 0 and 2
            djedAPR = Math.min(Math.max(djedAPR,0),10);
            setLpAPR(djedAPR);
        })()

    },[])

    const ratio = props.ratio;
    
    const { error: priceError, value: price } =
        useCall({
            contract: Djed,
            method:"scPrice",
            args:[0]
        }) ?? {};
    // dogecoin price
    const { error: dogePriceError, value: dogePrice } =
        useCall({
            contract: oracle,
            method:"lastPrice",
        }) ?? {};
    
    // user balance of dogedollar
    const { error: balanceError, value: balance } =
        useCall({
            contract: DogeDollar,
            method:"balanceOf",
            args:[account]
        }) ?? {};

    // get the contract address of Djed reservecoin
    const { error: reserveError, value: reserve } =
        useCall({
            contract: Djed,
            method:"reserveCoin",
        }) ?? {};
    const { error: leverageError, value: leverage } =
        useCall({
            contract: Djed,
            method:"leverage",
        }) ?? {};
    //console.log('reserve coin price (to sell): ',formatEther(await this.djed.rcTargetPrice(0)));

    const { error: rcPriceError, value: rcPrice } =
    useCall({
        contract: Djed,
        method:"rcTargetPrice",
        args:[0]
    }) ?? {};
    const tvl = useEtherBalance(config.djed.address)
    // use this and the ERC20 abi to get the balance of the reservecoin
    const { error: reserveBalanceError, value: reserveBalance } =
        useCall({
            contract: new Contract(reserve?reserve[0]:addresses.NULL,config.dogedollar.abi),
            method:"balanceOf",
            args:[account]
        }) ?? {};
    

    var priceUSD,priceDOGE,priceSC;
    if(dogePrice&&price){
        priceDOGE = ethers.utils.formatUnits(dogePrice[0]||0,6)-0; // oracle has 6 decimals
        priceSC = ethers.utils.formatEther(price[0]||0)-0; // djed has 18 decimals
        priceUSD = priceDOGE*priceSC;
    }

    var ratioPercent = ratio?((100*ethers.utils.formatEther(ratio[0]||0)-0).toFixed(2)-0):0;

    var minRatio = 300;
    var dangerRatio = 100; //<= 100% collateralization means coin is not fully backed
    var maxRatio = 600;
    var ratioStatus = ratioPercent<dangerRatio?'danger':(ratioPercent<minRatio?'low':(ratioPercent<maxRatio?'optimal':'high'));
    if(ratioPercent<=0){
        ratioStatus = '';
    }
    var ratioColors = {
        'danger':'#f44336',
        'low':'#ff9800',
        'optimal':'#4caf50',
        'high':'#00bcd4',
        '':'',
    }
    var ratioColor = ratioColors[ratioStatus];

    const stats = [{
        'label':'Backing',
        'value':ratioPercent?ratioPercent+'%':'--',
        'color':ratioColor,
        'sub':(<i style={{color:ratioColor,position:'absolute',textAlign:'center',fontSize:'10px'}}>status: {ratioStatus}</i>)
    },{
        'label':'Price',
        'value':priceUSD?(priceUSD.toFixed(2)+'$'):'--',
        'color':Math.abs(priceUSD-1)<0.05?'':'#ff9800', // allow 5% deviation from 1$
        'help':(<p>Oracle Price: {priceDOGE?priceDOGE.toFixed(6):'--'}<br/>DOGE per USD: {priceSC?priceSC.toFixed(6):'--'}<br/>Off peg (%) : {priceUSD?((priceUSD-1)*100).toFixed(2):'--'}</p>)
    },{
        'label':'my DUSD',
        'value':balance?(ethers.utils.formatUnits(balance[0]||0,6)-0):'--',
    },{
        'label':'my LP',
        'value':reserveBalance?(ethers.utils.formatUnits(reserveBalance[0]||0,6)):'--',
        'sub':(<i style={{color:'#00bcd4',position:'absolute',textAlign:'center',fontSize:'10px'}}>APR: {lpAPR===2?'>':''}{(lpAPR*100).toFixed(2)}%</i>),
        'help':(<p>DOGE per LP: {rcPrice?(ethers.utils.formatEther(rcPrice[0])-0).toFixed(2):'--'} <br/> my LP worth: {(rcPrice&&reserveBalance)?(ethers.utils.formatUnits(rcPrice[0].mul(reserveBalance[0]),18+6)-0).toFixed(2):'--'}<br/> Effective Leverage: {leverage?(ethers.utils.formatEther(leverage[0])-0).toFixed(2):'--'}x<br/>TVL: {tvl?(ethers.utils.formatEther(tvl)-0).toFixed(2):'--'} DOGE</p>)
    }]


    var maxlen = 0;
    for(var i=0;i<stats.length;i++){
        stats[i].value = stats[i].value.toString().substring(0,8)
        if(stats[i].value.length>maxlen){
            maxlen = stats[i].value.length;
        }
    }
    var fontsz = maxlen>=8?18-(maxlen-8)/2:18+'px';

    return (
        <div style={{padding:'32px',textAlign:'left',fontWeight:'500'}}>
            <HStack>
                {stats.map(function(stat){
                    return (
                        <StackItem key={stat.label} basis="25%">
                            <StatHolder>
                                <StatLabel><DataTableRow title={stat.label}  help={stat.help} /></StatLabel>
                                <StatValueHolder>
                                    <span style={{fontSize:fontsz,color:stat.color||''}}>{stat.value}</span>
                                </StatValueHolder>
                                <>
                                    {stat.sub}
                                </>
                            </StatHolder>
                        </StackItem>
                    )
                })}
                
            </HStack>
            
        </div>
    )
}

const StakeStack = (props) => {
    return (
        <>
            <StakeIcon coin={props.swap[0]} />
            <ArrowRight />
            <StakeIcon coin={props.swap[1]} />
        </>
    )
}



const DogeDollar = (props) => {

    const { account, activateBrowserWallet,library, deactivate, error } = useEthers();
    const [action,setAction] = useState('mint');
    const [estimate,setEstimate] = useState(0);
    const [amountRaw,setAmountRaw] = useState(0);

    var amount = amountRaw;
    if(typeof(amountRaw)==='string'){
        amount = parseFloat(amountRaw);
        if(isNaN(amount)){
            amount = 0;
        }
    }

    const prevVersions = {
        '1.0.0':'0x7c807e053170FF4B48DBeD71C3368ef7cE668062',
    }
    const currentVersion = '1.1.0';
    const backVersion = {};
    for(var key in prevVersions){
        backVersion[prevVersions[key]] = key;
    }

    const chainInfo = props.chainInfo;

    const settings = props.settings;
    const config = settings;
    
    const functions = {...config.functions};
    const isStaking = true;
    var currency = functions[action].swap[0];

    const Djed = new Contract(config.djed.address,config.djed.abi);

    const { error: ratioError, value: ratio } =
        useCall({
            contract: Djed,
            method: "ratio",
        }) ?? {};

    const { error: reserveError, value: reserve } =
        useCall({
            contract: Djed,
            method:"reserveCoin",
        }) ?? {};
    var currencyAddress = {...config.currencies};
    // add djed, dogedollar, and reservecoin to the list of currencies
    currencyAddress['Djed'] = config.djed.address;
    currencyAddress['DUSD'] = config.dogedollar.address;
    currencyAddress['LP'] = reserve?reserve[0]:addresses.NULL;
    currencyAddress['DOGE'] = chainInfo.weth;

    

    var fn = functions[action];
    //
    // callStatic the swap function of dogedollar to get the estimate
    // const { error: swapError, value: swapEst } =
    //     useCall({
    //         contract: DogeDollar,
    //         method:"swap",
    //         args:[functions[action].swap[0],functions[action].swap[1],parseEther(amount)],
    //     }) ?? {};
    // use a debounce and callStatic the swap function of dogedollar to get the estimate with a useEffect based on amount (use ethers instead of usedapp hooks)
    const [swapEst,setSwapEst] = useState(0);
    const [swapError,setSwapError] = useState(0);
    const [estLoading,setEstLoading] = useState(0);
    useEffect(()=>{
       
        if(!amount||amount<=0){
            return;
        }
        setEstLoading(1);
        const timeout = setTimeout(async () => {

            // console.log(fn,action)
            var provider = window.signer;
            if(!window.signer){
                provider = window.provider;
                if(fn.swap[0]!=='DOGE'){
                    setSwapError('Please connect wallet for estimate');
                    setSwapEst(0);
                    setEstLoading(0);
                    return;
                }
            }
            var addr = account?account:addresses.DEV;
            const contract = new ethers.Contract(config.dogedollar.address,config.dogedollar.abi,provider);
            const Djed = new ethers.Contract(config.djed.address,config.djed.abi,provider);
            var indecimals = config.tokenDecimals[fn.swap[0]]||18;
            var inammt = parseUnits(amount+"",indecimals);
            
            // check if the user has enough balance of the input currency
            var inbalance = (fn.swap[0]==='DOGE')?await window.provider.getBalance(addr):await new ethers.Contract(currencyAddress[fn.swap[0]],abis.erc20,provider).balanceOf(addr);

            var enough = inbalance.gte(inammt);
            if(!enough){
                console.log('not enough balance')
                setSwapEst(0);
                setSwapError('Insufficient balance');
                setEstLoading(0);
                return;
            }
            try{
                var est;
                if(action==='addLiquidity'){
                    est = await Djed.callStatic.buyReserveCoins(addr,0,addresses.NULL,{
                        value:inammt
                    });
                }else if(action==='removeLiquidity'){
                    est = await Djed.callStatic.sellReserveCoins(inammt,addr,0,addresses.NULL);
                }else{
                    est = await contract.callStatic.swap(currencyAddress[fn.swap[0]],currencyAddress[fn.swap[1]],addr,inammt,0,addresses.NULL,{
                        value:currency==='DOGE'?inammt:0
                    });
                }
                var decimals = config.tokenDecimals[fn.swap[1]]||18;
                est = ethers.utils.formatUnits(est,decimals)-0;

                setSwapEst(est);
                setSwapError(0);
            }catch(e){
                console.log(e);
                setSwapEst(0);
                if(config.functions[action].error){
                    setSwapError(config.functions[action].error);
                }else{
                    setSwapError('Input too high');
                }
            }
            setEstLoading(0);
        }, 500);
        return () => clearTimeout(timeout);
    }, [amount]);
    async function swap(){
        if(!account){
            activateBrowserWallet();
            return;
        }
        if(!amount||amount<=0){
            return;
        }
        window.changeAlert(true,{
            title: config.functions[action].name,
            subtitle: 'Starting transaction',
            body: 'Please approve the transactions in your metamask wallet as they come up',
            icon:'loader'
        })
        try{
            const contract = new ethers.Contract(config.dogedollar.address,config.dogedollar.abi,window.signer);
            const Djed = new ethers.Contract(config.djed.address,config.djed.abi,window.signer);
            var indecimals = config.tokenDecimals[fn.swap[0]]||18;
            var inammt = parseUnits(amount+"",indecimals);
            // do approvals if nessisary
            var tx;
            if(currency!=='DOGE'){
                var token = new ethers.Contract(currencyAddress[fn.swap[0]],abis.erc20,window.signer);
                var targetAddr = (action==='removeLiquidity')?config.djed.address:config.dogedollar.address;
                var allowance = token.allowance(account,targetAddr);
                if(allowance<inammt){
                    var atx = await token.approve(targetAddr,inammt);
                    await atx.wait();
                }
            }
            if(action==='addLiquidity'){
                tx = await  Djed.buyReserveCoins(account,0,addresses.NULL,{
                    value:inammt
                });
            }else if(action==='removeLiquidity'){
                tx = await Djed.sellReserveCoins(inammt,account,0,addresses.NULL);
            }else{
                tx = await contract.swap(currencyAddress[fn.swap[0]],currencyAddress[fn.swap[1]],account,inammt,0,addresses.NULL,{
                    value:currency==='DOGE'?inammt:0
                });
            }
            await tx.wait();

            window.changeAlert(true,{
                title: 'Success',
                subtitle: 'Your transaction is complete',
                body: '',
                icon:'success'
            })
            
        }catch(e){
            if(e.code===4001&&window.changeAlert){
                window.changeAlert(true,{
                    title: 'Error',
                    subtitle: 'User denied transaction',
                    body: 'Please try again',
                    icon:'error'
                  })
            }else if(e.data&&e.data.message&&e.message.startsWith('incorrect nonce,')){
                window.changeAlert(true,{
                    title: 'Error',
                    subtitle: 'Nonce desync error',
                    body: 'Please make sure you are using the official dogechain RPC (https://rpc.ankr.com/dogechain). To update your configuration, please delete DogeChain from your metamask networks and let HellHound re-add it',
                    icon:'error'
                  })
                //https://rpc.dogechain.dog
            }else if(e.data&&e.data.message){
                window.changeAlert(true,{
                    title: 'Error',
                    subtitle: e.data.message,
                    body: 'Please try again',
                    icon:'error'
                  })
            }
        }
        
        
    }
            


    async function useMax(){
        console.log(chainInfo.native,currency)
        if(!account){
            return;
        }
        if(currency==='DOGE'){
            setAmountRaw(formatEther((await window.provider.getBalance(account)).sub(ethers.utils.parseEther('2'))))
        }else{
            const contract = new ethers.Contract(currencyAddress[currency],abis.erc20,window.signer);
            var max = ethers.utils.formatUnits((await contract.balanceOf(account)),config.tokenDecimals[currency]||18);
            setAmountRaw(max);
        }
    }

    var stats = [
        {
            title: 'est '+fn.swap[1]+' received',
            value: swapEst?swapEst.toFixed(6):'--',
        },
    ]
    
    if(swapError){
        stats.push({
            title: '',
            value: swapError,
            strong: 'error',
        })
    }

    // for(var fn in config.functions){
        
    // }

    

    return (
        <>
        <div style={{textAlign:'center'}}>
            <H2 style={{marginBottom:'14px'}} color="text">DogeDollar</H2>
            <h4 style={{marginBottom:'16px',color:"rgba(255, 255, 255, 0.8)",fontWeight:"500"}}>Interact with the first stablecoin backed by DOGE!</h4>
            <Block  style={{padding:'0px 0px 0px 0px'}}>
                <StableInfo settings={settings} ratio={ratio} />
                <Block color="accent" >
                    {/*full width select for all config.functions*/}
                    <Select 
                        fullwidth
                        style={{marginBottom:'16px'}}
                        label="Action"
                        value={action}
                        onChange={(event)=>{setAction(event);setSwapError(0);setAmountRaw(0);setSwapEst(0);}}
                        leftDecorator={
                            <StakeStack swap={config.functions[action].swap} />
                        }
                    >
                        {
                            Object.keys(config.functions).map(function(key){
                                return (
                                    <Option key={key} value={key}
                                        leftDecorator={
                                            <StakeStack swap={config.functions[key].swap} />
                                        }
                                    >
                                        {config.functions[key].name}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                    
                    <Input fullwidth
                        leftDecorator={
                            <StakeIcon coin={config.functions[action].swap[0]} />
                        }
                        rightDecorator={
                            <Button color="primary" onClick={useMax} size="xs" variant="translucent">
                                {(isStaking&&currency==='DOGE')?'Max-2':'Max'}
                            </Button>
                        }
                        style={{marginBottom:'10px'}}
                        placeholder="Amount"
                        value={amountRaw}
                        onChange={(event)=>{setAmountRaw(event.target.value)}}
                    />
                    {/* show estimated recieved with coin icon */}
                    <div style={{marginBottom:'16px',textAlign:'left'}}>
                        <DataTable style={{display:''}}>
                            {stats.map(function(stat){
                                return (
                                    <DataTableRow key={stat.title+'stat'} loading={!ratio||estLoading} title={stat.title} help={stat.help}>
                                        {/* !fees||!(outEst&&(swapEst||doubleLiq||isWant)&&amount) */}
                                        <Text color={stat.strong} size="xs" strong={!!stat.strong}>{stat.value}</Text>
                                    </DataTableRow>
                                )
                            })}
                        </DataTable>
                    </div>

                    <Button fullwidth
                        color="primary"
                        style={{marginTop:'16px'}}
                        onClick={swap}
                        disabled={!!swapError}
                        // onClick={isStaking?onUnstake:onStake}
                        // disabled={isStaking?(!amountRaw||amountRaw<=0):(!sharesRaw||sharesRaw<=0)}
                    >
                        {account?(config.functions[action].name):'Connect Wallet'}
                    </Button>
                
                    {
                        config.dogedollar.userSpec ? (
                            <div style={{marginTop:'32px',marginBottom:'0px',textAlign:'center'}}>
                                <a href={chainInfo.explorer+'address/'+config.dogedollar.address} target="_blank" style={{color:'#ff9800',textDecoration:'underline'}}>Using DogeDollar at: {config.dogedollar.address} 
                                {backVersion[config.dogedollar.address] ? ' (v'+backVersion[config.dogedollar.address]+')' : ''}
                                </a>
                            </div>
                        ) : ''
                    }
                </Block>
                
            </Block>
            
        </div>

        <Accordion
            style={{marginTop:'16px'}}
            summary={"How does it work?"}
            key = {1}
        >
            DogeDollar is a stablecoin backed primarily by Dogecoin on Dogechain. 
        </Accordion> 
        <Accordion
            style={{marginTop:'16px'}}
            summary={"My DogeDollar isnt showing up"}
            key = {2}
        >
            It is possible that you may have been using a different version of DogeDollar. All LP is preserved between versions, but the DogeDollar contract address changes. 
            To migrate, simply burn your dogedollar on the old version then re-mint on the new version.
            <br/>
            <br/>
            Current Version: {currentVersion}
            <br/>
            Links to previous versions:
            <br/>

            {
                Object.keys(prevVersions).map(function(key){
                    return (
                        <a key={key} href={"/#/dogedollar?contract-dogedollar="+prevVersions[key]} target="_blank">Version {key}</a>
                    )
                })
            }
        </Accordion>
        </>
    )
}
export default DogeDollar;