import { ethers } from "ethers";
// calculates APY from earnings and timeframe
// earnings is of the multiplicitive form, (e.g. 1.1 = protocol earned 10% of the total value locked)
// timeframe is in seconds, (e.g. 86400 = 1 day)
// returns the APY as a percentage
export function calcAPY(earnings,timeframe){
    const secondsInYear = 31536000;
    const APY = Math.pow(earnings,secondsInYear/timeframe);
    return APY;
}
// same thing but APR (non-compounding)
export function calcAPR(earnings,timeframe){
    const secondsInYear = 31536000;
    const rawEarnings = earnings-1;
    const APR = rawEarnings/(timeframe/secondsInYear);
    return APR;
}

export async function calcDjedEarnings(contract,oracle,provider){
    // call the rcBuyingPrice() function
    const rcBuyingPrice = await contract.rcBuyingPrice(0);
    const originalRcBuyingPrice = ethers.utils.parseEther('10');
    const deploymentDateTime = (new Date('July-15-2023')-0)/1000;
    const currentDateTime = (new Date()-0)/1000;
    const timeSinceDeployment = currentDateTime - deploymentDateTime;
    const leverage = await contract.leverage(); // leverage is calculated as Reserve / Equity 
    const oraclePrice = await oracle.lastPrice();
    const originalPrice = ethers.utils.parseUnits('0.068948',6); // orignal price;
    const earningsAsMultiple = rcBuyingPrice.mul(ethers.utils.parseEther('1')).div(originalRcBuyingPrice);
    const priceMovementAsMultiple = oraclePrice.mul(ethers.utils.parseEther('1')).div(originalPrice);



    var l = ethers.utils.formatEther(leverage)-0;
    var p = ethers.utils.formatEther(priceMovementAsMultiple)-0;
    var e = ethers.utils.formatEther(earningsAsMultiple)-0;

    var earningsFromLeverage = ((l-1)*(p-1));

    var earningsFromFees = e - earningsFromLeverage;

    // console.log('earningsAsMultiple',e);
    // console.log('leverage',l);
    // console.log('priceMovementAsMultiple',p);
    // console.log('earningsFromLeverage',earningsFromLeverage);
    // console.log('earningsFromFees',earningsFromFees);
    return earningsFromFees;
}

