import { FC } from 'react';
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { shortenAddress, useCall, useEthers, useLookupAddress, ChainId } from "@usedapp/core";
import { Fantom } from '@usedapp/core'
import { addresses, abis,deployments } from "@my-app/contracts";

import { Button, Eth,Select,Option } from "@lidofinance/lido-ui";

import { networks } from "@my-app/contracts";
import { HeaderWalletChainStyle } from './headerWalletStyles';
import Swal from 'sweetalert2'
import { Switch } from '@mui/material';
import { render } from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import StakeIcon from '../staking/stakeIcons';



function WalletButton(props) {

  const chainInfo = props.chainInfo;

  const [rendered, setRendered] = useState("");
  const [chainid,setChainId] = useState();

  const networkNames= ['Polygon','DogeChain'];
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('DEVELOPMENT MODE')
      networkNames.push('Localhost');
  } else {
      console.log('PRODUCTION MODE')
      // production code
  }
  const networkIds = networkNames.map((networkName) => { return ChainId[networkName] });

  const [targetNetwork, setTargetNetwork] = useState(chainInfo.name);


  const ens = useLookupAddress();

  const { chainId,  account, activateBrowserWallet,library, deactivate, error } = useEthers();

  var validNetwork = networkIds.includes(chainId);
 



  async function switchNetwork(chainId){
    var networkInfo = networks[chainId];
    console.log('SWITCHING TO',chainId,networkInfo)
    if(networkInfo){
      if(!window.ethereum||rendered === ""){ // they are just browsing
        console.log('just browsing or no metamask',chainId)
        const provider2 = new ethers.providers.JsonRpcProvider(networkInfo.rpcUrls[0]);
        window.allEthers.activate(provider2);
        
        // window.refreshUIForChain(chainId);
        return;
      }
      try{
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networkInfo.chainId }]
        });
      }catch(err){
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              networkInfo
            ]
          });
        }
      }

      
    }else{
      Swal.fire({
        title: 'Network not supported',
        text: 'Please switch to a supported network',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }
  }



  // console.log(chainId, switchNetwork, account, activateBrowserWallet,library, deactivate, error)
  if(library){
    window.provider = library;
    window.signer = library.getSigner();
    // var scytheLock = deployments[1337][0].contracts.ScytheLock;
    // console.log(deployments[1337][0].contracts)

    // var ScytheLock = new ethers.Contract(scytheLock.address,scytheLock.abi,window.signer);

    // (async () => {
    //   console.log((await ScytheLock.reflection()).toNumber());
    // })();
    // console.log(deployments[1337][0].contracts.Multicall.address)

  }
  useEffect(() => {
    window.connectedToWallet = true;
    if (ens) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      window.connectedToWallet = false;
      setRendered("");
    }
  }, [account, ens, setRendered]);


  useEffect(() => {
    (async function(){
      if(account){
        const { chainId } = await window.signer.provider.getNetwork();
        console.log('CHAINID',chainId,window.signer.provider.connection.url=='metamask')

        setChainId(chainId);
      }else{
        setChainId(null);
      }
      
    })();
    
  }, [chainId]);

  useEffect(() => {
    if (error) {
     // console.log("Error while connecting wallet:", error.message);
    }
  }, [error]);

  async function onClick(){
    
    if (!account) {
      if(!localStorage.getItem('privacy')){
        var newp = await Swal.fire({
            title: 'Terms of Service',
            html: "By using HellHound I accept the <a target='_blank' href='/#/terms'>Terms of Service</a>",
            icon: 'info',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Accept & Connect'
          }).then((result) => {
            return result.isConfirmed;
          });
        console.log(newp);
        if(!newp){
          return false;
        }
        localStorage.setItem('privacy',true);
  
      }
      activateBrowserWallet();
    } else {
      deactivate();
    }
  }
  async function switchToNative(){
    changeNetwork(targetNetwork);
    
  }
  function changeNetwork(newNetwork){
    console.log(newNetwork);

    if(ChainId[newNetwork]){
      switchNetwork(ChainId[newNetwork]).then((res)=>{
        console.log(res);
      });
    }
    

   // setTargetNetwork(newNetwork);
  }
  window.changeNetwork = setTargetNetwork;
  
  return (
    <>
      {/* <p>{chainId}</p> */}
      {(rendered !== "" && !validNetwork)?(<Button
        size ='sm'
        color="error"
        variant="filled"
        style={{marginRight: '10px'}}
        className="animate__animated"
        onClick={switchToNative}
      >
        <FontAwesomeIcon icon={faTriangleExclamation} />  Switch To {targetNetwork}
      </Button>):(
        <Select 
            variant="small"
            arrow="small"
            themeOverride="light"
            style={{maxWidth:'170px',marginRight: '10px'}}
            leftDecorator={<StakeIcon coin={targetNetwork} />}
            onChange={changeNetwork}
            value={targetNetwork}
        >
          {networkNames.map((network) => (
            <Option key={network} value={network} leftDecorator={<StakeIcon coin={network} />}>
              {network}
            </Option>
          ))}
        </Select>
      )}
      <Button
        size ={'sm'}
        color={rendered !== "" ? "secondary" : "primary"}
        variant={rendered !== "" ? "translucent" : "filled"}
        onClick={onClick}
      >
        {/* <Eth/> */}
        {rendered === "" && "Connect Wallet"}
        {rendered !== "" && rendered}
      </Button>
    </>
    
  );
}
const HeaderWallet: FC = (props) => {
  const chainInfo = props.chainInfo;
  return (<>
    <WalletButton chainInfo={chainInfo} />
  </>)
  // const { active } = useWeb3();
  // const { chainId } = useSDK();

  // const chainName = CHAINS[chainId];
  // const testNet = chainId !== CHAINS.Mainnet;
  // const showNet = testNet && active;

  // return (
  //   <>
  //     {showNet && (
  //       <HeaderWalletChainStyle $color={getChainColor(chainId)}>
  //         {chainName}
  //       </HeaderWalletChainStyle>
  //     )}
  //     {active ? <WalletButton /> : <WalletConnect size="sm" />}
  //     <ThemeToggler />
  //   </>
  // );
};

export default HeaderWallet;
