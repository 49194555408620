function StakeIcon(props){
    var lowercases = {
        "FTM":'ftm',
        "beFTM":'beftm',
        "moobeFTM":'moobeftm',
        "DAI+USDC":'dai+usdc',
        "USDC":'usdc',
        "DAI":'dai',
        "mooSpiritUSDC-DAI":'moospiritusdc-dai',
    }


    return (
        <img alt={props.coin} width={24} height={24} src={'/coins/'+props.coin.toLowerCase()+'.png'}/>
    )

}
export default StakeIcon