import styled from 'styled-components';

export const SliderContainerStyle = styled.div`
display: flex;
-webkit-box-pack: center;
justify-content: center;
margin-bottom: 24px;`

export const SliderHolderStyle = styled.div`    width: 300px;
height: 44px;
background-color: rgb(19, 19, 23);
border-radius: 22px;
position: relative;
display: flex;
cursor:pointer;
justify-content: space-around;
-webkit-box-align: center;
align-items: center;
user-select: none;
margin: 0px;`

export const SliderOptionStyle = styled.p`
z-index: 2;
margin: 0px;
opacity: 1;
transition: opacity 0.3s ease 0s;
line-height: 1.6em;`

export const SliderStyle = styled.div`
    width: 150px;
    height: 40px;
    background-color: rgb(52, 52, 61);
    border-radius: 20px;
    position: absolute;
    left: 2px;
    transition: left 0.3s ease 0s;
    top: 2px;
    z-index: 1;
`
export const StatHolder = styled.div`
margin-right: 18px;
flex-basis: 50%;
-webkit-box-flex: 1;
flex-grow: 1;
font-size: 12px;
line-height: 1.6em;
`
export const StatLabel = styled.div`
display: flex;
flex-direction: row;
font-size: 12px;
font-weight: 400;
-webkit-box-pack: start;
justify-content: flex-start;
-webkit-box-align: center;
align-items: center;`

export const StatValueHolder = styled.div`
margin-top: 2px;
font-size: 18px;
line-height: 1.4em;
font-weight: 700;
white-space: nowrap;
`