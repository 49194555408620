const networks = {
    '1337':{
        chainName: 'Localhost',
        chainId: '0x539',
        nativeCurrency: { name: 'Ether', decimals: 18, symbol: 'ETH' },
        rpcUrls: ['http://localhost:8545'],
        blockExplorerUrls: ['http://localhost:8545']
    },
    '2000': {
        chainName: 'DogeChain',
        chainId: '0x7d0',
        nativeCurrency: { name: 'WDOGE', decimals: 18, symbol: 'WDOGE' },
        rpcUrls: ['https://rpc.dogechain.dog/'],
        blockExplorerUrls: ['https://explorer.dogechain.dog']
    },
    '137':{
        chainName: 'Polygon',
        chainId: '0x89',
        nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
        rpcUrls: ['https://polygon.llamarpc.com'],
        blockExplorerUrls: ['https://polygonscan.com/']
    }
}
export default networks;