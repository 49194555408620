import styled from 'styled-components';

export const BackgroundGradientSvgStyle = styled.svg`
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    margin: ${({ height }) => -height / 2}px 0 0 ${({ width }) => -width / 2}px;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    pointer-events: none;
  `;
  
export const BackgroundGradientStartStyle = styled.stop`
stop-color: ${({ theme }) => theme.colors.primary};
`;

export const BackgroundGradientStopStyle = styled.stop`
stop-color: ${({ theme }) => theme.colors.background};
`;

const BackgroundGradient = function(props){

    const { width, height} = props;
    return (
        <BackgroundGradientSvgStyle width={width} height={height}>
            <radialGradient id="background-gradient" cx="50%" y="50%">
                <BackgroundGradientStartStyle offset="0%" />
                <BackgroundGradientStopStyle offset="100%" />
            </radialGradient>
            <rect
                width={width}
                height={height}
                opacity=".1"
                fill="url(#background-gradient)"
            />
        </BackgroundGradientSvgStyle>
    );
}
export default BackgroundGradient;