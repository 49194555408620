import styled from "styled-components";
import { Container, Text, H2 } from '@lidofinance/lido-ui';
import { createGlobalStyle } from 'styled-components';

export const MainStyle = styled(Container)`
  position: relative;
  margin-top: ${({ theme }) => theme.spaceMap.sm}px;
  margin-bottom: ${({ theme }) => theme.spaceMap.sm}px;
`;


export const Body = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  margin-top: 40px;
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 16px;
  margin: 0px 20px;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
`;

// export const Container = styled.div`
//   background-color: #282c34;
//   display: flex;
//   flex-direction: column;
//   height: calc(100vh);
// `;

export const Header = styled.header`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 70px;
`;

export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 8px;
`;


export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
  html,
  body {
    width: 100%;
  }
  body {
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    position: relative;
    box-sizing: border-box;
    font-size: ${({ theme }) => theme.fontSizesMap.xs}px;
    line-height: 1.5em;
    font-weight: 500;
    text-size-adjust: none;
  }
  a {
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryHover};
    }

    &:visited {
      color: ${({ theme }) => theme.colors.primaryVisited};
    }
  }
`;

export const Footer = function(props){
  return(
    <div>

    </div>
  )
}

export const Terms = function(props){
  return (
    <>
      <H2>Terms of Service</H2><br/>
      <Text>
        Use of this site and its associated services is subject to the following terms and conditions: <br />
        <br />
        HellHound is an experimental protocol, and I understand the risks associated with using the protocol and its associated functions.<br />
        <br />
        I confirm that I am familiar with the concepts of decentralized finance, and I have read and understand the HellHound Documentation.<br />
        <br />  
        I understand that my interaction with HellHound (including its website, smart contracts, or any related functions) may place my tokens at-risk, and I hereby release HellHound, its contributors, and affiliated service providers from any and all liability associated with my use of HellHound.<br />
        <br />
        I understand that these terms may be changed at any time without notice, and I am responsible for remaining up to date with any changes.<br />
        <br />
        I am lawfully permitted to access this site and my use of and interaction with HellHound is not in contravention of any laws governing my jurisdiction of residence or citizenship.<br />
      </Text>
    </>
  )

}