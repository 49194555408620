import { useState,useEffect } from 'react';
import { useEthers,useCall,useLogs,useCalls,useTokenBalance,useEtherBalance } from "@usedapp/core";
import { formatEther } from '@ethersproject/units'
import { Contract } from "@ethersproject/contracts";
import {HStack,VStack,StackItem,Text,Block,DataTableRow,Heading,H1,H3,Button,Container,Twitter,Telegram} from '@lidofinance/lido-ui';
import {Grid,Box} from '@mui/material';
import logo from "../../scythesymbol.png";
import WOW from 'wowjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {StatHolder,StatLabel,StatValueHolder} from "../staking/stakingStyles.js";
import { ethers } from "ethers";
import CountUp from 'react-countup';
import { faBook } from '@fortawesome/free-solid-svg-icons'

function estApy(exchange){

  var start = 1657425600000;
  var timePassed = (new Date()-start)/(1000*60*60*24*365);
  var returns = Math.pow(Math.E,Math.log(exchange)*(1/timePassed));
  console.log(returns,timePassed)
  return returns;
  
}


const APYDisplay = (props) => {
  const config = props.settings;

  const [bApy,setbApy] = useState(0);
  const [sApy,setsApy] = useState(0);

  const calls = [config.contract.address,config.vaultAddress].map(address => ({
    contract: new Contract(address, config.contract.abi),
    method: 'getPricePerFullShare',
    args: []
  })) ?? []
  const results = useCalls(calls) ?? [];

  useEffect(function(){
    if(results[0]&&results[1]&&results[0].value){
        setsApy(estApy(formatEther(results[0].value[0])-0));
    }
  },[results]);

  useEffect(() => {
    const url = "https://api.beefy.finance/apy";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();

        console.log('VAULT ',json[config.beefyVault])
        setbApy(json[config.beefyVault]);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  return(
    <StatHolder >
      {/* <DataTableRow title={config.symbol+' APY'} style={{fontSize:'12px',color:'#4feaa9'}} /> */}
        <StatLabel style={{color:'#4feaa9'}}><span style={{fontSize:'12px',textAlign:'center',color:'#4feaa9'}}>{config.symbol+' APY'}</span></StatLabel>
        <StatValueHolder>
          
            <span style={{fontSize:'34px',color:'white'}}>{(bApy&&sApy)?<CountUp decimals={2} duration={0.5} end={(((bApy+1)*sApy-1)*100).toFixed(2)-0} suffix="%" />:'--%'}</span>
        </StatValueHolder>
    </StatHolder>
  )
  
}

const infos = [
  {
    title:'Dynamic Reflections for Stable Yield',
    desc:'A small reflection fee is charge on withdrawl and distributed to vault users. Reflections are adjusted based on market conditions to stabilize yield and boost long-term APY',
    link:'https://docs.hellhound.dog',
    img:'reflect.png'
  },
  {
    title:'Leveraging the Entire DogEconomy',
    desc:'By utilizing community selected opportunities, Hellhound automatically migrates your tokens to the best strategies available',
    link:'https://docs.hellhound.dog',
    img:'ecosystem.png'
  },
  {
    title:'The $HLH Token',
    desc:'$HLH is a deflationary governance token used to select new opportunities for vaults. It is bought back and burned using deposit fees',
    link:'https://docs.hellhound.dog',
    img:'scythecoin.png'
  },

]

const Landing = (props) => {

  const config = props.settings;
  const chainInfo = props.chainInfo;
  
  const firstVault = chainInfo.vaults[0];


  // const calls = [config.usd.contract.address,config.usd.vaultAddress].map(address => ({
  //     contract: new Contract(address, config.usd.contract.abi),
  //     method: 'getPricePerFullShare',
  //     args: []
  //   })) ?? []
  // const results = useCalls(calls) ?? [];

  // console.log(results);

  // const { error: logError, value: logs } = useLogs({
  //   contract: new Contract(config.ftm.contract.address, config.ftm.contract.abi),
  //   event: 'Transfer',
  //   args: [null]
  // })?? {};

  // useLogs({

  // })
  useEffect(() => {
    return;
    var prices = {};
    function getPriceAtBlock(block){
      window.slFTM.getPricePerFullShare({blockTag: block}).then(function(result){
        window.ftmVault.getPricePerFullShare({blockTag: block}).then(function(result2){

          // prices[block] = {
          //   ftm: ,
          //   vault: result2
          // }
          console.log(ethers.utils.formatEther(result)*ethers.utils.formatEther(result2));
        });
        
      })
    }
    (async function(){

      console.log(config.ftm.contract.address, config.ftm.contract.abi, window.provider)
      var slFTM = new ethers.Contract(config.ftm.contract.address, config.ftm.contract.abi, window.provider);
      var ftmVault = new ethers.Contract(config.ftm.vaultAddress, config.ftm.contract.abi, window.provider);

      window.slFTM = slFTM;
      window.ftmVault = ftmVault;

      var filter = window.slFTM.filters.Withdraw();
      var withdrawls = await window.slFTM.queryFilter(filter);


      for(var i in withdrawls){
        var withdraw = withdrawls[i];

        getPriceAtBlock(withdraw.blockNumber);

        // window.slFTM.getPricePerFullShare({blockTag: withdraw.blockNumber}).then(function(result){
        //   console.log('',ethers.utils.formatEther(result));
        // })
        
        // var tx = await window.slFTM.getTransaction(withdraw.transactionHash);
        // var txReceipt = await window.slFTM.getTransaction
      }

      console.log(withdrawls);



      //await window.slFTM.functions.totalSupply({blockTag:43154157})


      // let eventFilter = slFTM.filters.Transfer()

      // let events = await slFTM.queryFilter(eventFilter);

      // console.log('SLFTM EVENTS: ')

      // console.log(events)
    })();
    

  }, [])

  return (
    <>
      <VStack
        className='animate__animated animate__fadeInRight'
        spacing="md"
        style={{position:'fixed',right:'25px',top:'50vh',fontSize:'24px',transform:'translate(-50%,-50%)'}}
      >
        <StackItem key={1}>
          <a href="https://discord.gg/PGdtwjqjuJ" style={{color:'white'}} target="_blank"> 
            <FontAwesomeIcon style={{marginLeft:'-3px'}}icon="fa-brands fa-discord" />
          </a>
        </StackItem>
        <StackItem key={2}>
          <a href="https://docs.hellhound.dog" style={{color:'white'}} target="_blank">
            <FontAwesomeIcon style={{}}icon={faBook} />
          </a>
        </StackItem>
        <StackItem key={3}>
          <a href="https://twitter.com/NetherworldDAO" style={{color:'white'}} target="_blank">
            <FontAwesomeIcon icon="fa-brands fa-twitter" />
          </a>
        </StackItem>
        {/* <StackItem>
          <a href="https://t.me/scythefinance" style={{color:'white'}} target="_blank">
            <FontAwesomeIcon icon="fa-brands fa-telegram" />
          </a>
        </StackItem>
        <StackItem>
          <a href="https://medium.com/@scythe.finance" style={{color:'white'}} target="_blank">
            <FontAwesomeIcon style={{marginLeft:'-3px'}} icon="fa-brands fa-medium" />
          </a>
        </StackItem> */}

      </VStack>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh',marginTop:'-82px',marginBottom:'82px'}}
      >

        <Grid item xs={3}>
          <Container size="content" style={{textAlign:'center'}} className="animate__animated animate__fadeInDown">
            <img src={logo} style={{height:'30vh',maxHeight:'3640px'}} />
            <H1 color="text" style={{fontWeight:700}}>
                  The Long Term Yield Protocol 
                </H1>
                  <Text
                    color="text"
                    size="sm"
                    
                >
                  Part of the Netherworld on DogeChain
                </Text>
            <a href={"/#/"+firstVault}><Button size="md" className="animate__animated animate__pulse animate__delay-1s animate__repeat-2" style={{backgroundColor:'rgb(254, 196, 67)',color:'black',marginTop:'20px'}}>Deposit Now</Button></a>
          </Container>
          {/* <Container size="full" style={{position:'absolute',top:'100vh'}}>
            <HStack style={{marginTop:'-100px'}}  className="animate__animated animate__fadeInUp">
              <StackItem key={'usd'} basis="50%">
                <APYDisplay settings={config['usd']} />
              </StackItem>
              <StackItem key={'ftm'} basis="50%">
                <APYDisplay settings={config['ftm']} />
              </StackItem>
            </HStack>
          </Container> */}
        </Grid>   
      </Grid>
      {
        infos.map((info,index)=>{
          return (
            <Box key={index} style={{marginBottom:'64px'}} className={(index%2==1)?"wow animate__fadeInLeft":"wow animate__fadeInRight"}>
              <Grid container spacing={8}>
                {
                  (index%2==1)?(<Grid item xs={5}>
                    <img src={'/'+info.img} style={{maxWidth:'100%'}}/>
                  </Grid>):(<></>)
                }
                <Grid item xs={7}>
                  <H3 color="text">
                    {info.title}
                  </H3>
                  <Box style={{marginTop:'16px'}}>
                    <Text>
                      {info.desc}
                    </Text>
                    <a target="_blank" href={info.link}><Button size='sm' variant="outlined" color="primary" style={{marginTop:'16px'}} >Learn More</Button></a>
                  </Box>
                </Grid>
                {
                  (index%2==0)?(<Grid item xs={5}>
                    <img src={'/'+info.img} style={{maxWidth:'100%'}}/>
                  </Grid>):(<></>)
                }
                
              </Grid>
            </Box>
          )
        })
      }
      

    </>
  );
}

export default Landing;
