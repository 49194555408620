import {HStack,VStack,StackItem,Text,Block,DataTableRow,Heading,H1,H3,Button,Container,Twitter,Telegram} from '@lidofinance/lido-ui';
import {Grid,Box} from '@mui/material';
import logo from "../../scythesymbol.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

const Soon = function() {
    document.body.style.overflow='hidden'
    return (
        <>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh',marginTop:'-82px',marginBottom:'82px'}}
            >

                <Grid item xs={5}>
                <Container size="full" style={{textAlign:'center'}} className="animate__animated animate__fadeInDown">
                    <img src={logo} style={{height:'35vh',maxHeight:'3640px'}} />
                    <H1 color="text" style={{fontWeight:800,fontSize:'48px'}}>
                        Scythe Finance
                        </H1>
                        <Text
                            color="text"
                            size="sm"
                            
                        >
                            Coming Soon to the Fantom Opera
                        </Text>
                    <a target="_blank" href="https://discord.gg/2xsnkyZTG6"><Button size="md" className="animate__animated animate__pulse animate__delay-1s animate__repeat-2" style={{backgroundColor:'#4feaa9',color:'black',marginTop:'20px'}}>Get Updated</Button></a>
                </Container>
                <Container size="content" style={{position:'absolute',top:'100vh',left:'0vh',textAlign:'center'}}>
                    <HStack style={{marginTop:'-50px',color:'white',fontSize:'32px'}}  className="animate__animated animate__fadeInUp">
                        <StackItem basis='25%'>
                            <a href="https://discord.gg/2xsnkyZTG6" style={{color:'white'}} target="_blank">
                                <FontAwesomeIcon icon="fa-brands fa-discord" />
                            </a>
                        </StackItem>
                        <StackItem basis='25%'>
                            <a href="https://twitter.com/ScytheFinance" style={{color:'white'}} target="_blank">
                                <FontAwesomeIcon icon="fa-brands fa-twitter" />
                            </a>
                        </StackItem>
                        <StackItem basis='25%'>
                            <a href="https://medium.com/@scythe.finance" style={{color:'white'}} target="_blank">
                                <FontAwesomeIcon icon="fa-brands fa-medium" />
                            </a>
                        </StackItem>
                        <StackItem basis='25%'>
                            <a href="https://t.me/scythefinance" style={{color:'white'}} target="_blank">
                                <FontAwesomeIcon icon="fa-brands fa-telegram" />
                            </a>
                        </StackItem>
                    </HStack>
                </Container>
                </Grid>   
            </Grid>
        </>
    );
}
export default Soon;

{/* <VStack
className='animate__animated animate__fadeInRight'
style={{position:'fixed',right:'25px',top:'50vh',transform:'translate(-50%,-50%)'}}
>


</VStack> */}