import { FC } from 'react';
// import Link from 'next/link';
import { LidoLogo } from '@lidofinance/lido-logo';
import logo from "../../scythesymbol.png";
import {
  HeaderStyle,
  HeaderLogoStyle,
  HeaderActionsStyle,
} from './headerStyles';
import {Button,ButtonIcon,Stake,Wrap} from '@lidofinance/lido-ui';
import HeaderWallet from './headerWallet';
import { Link,useMatch } from "react-router-dom";

const HeaderLink = (props) => {

  return (
    <Link to={props.href}>
      {props.children}
    </Link>
  )
}
const Infos = {
  'usd':'USD Vault',
  'usd-polygon':'USD Vault',
  'hlh':'Stake HLH',
  'hlh_wwdoge':'Stake HLH/WWDOGE LP',
  "hlh_usdc-polygon":'Stake HLH/USDC LP',
  "dogedollar":"Dogedollar"
}


const Header = (props) => {

  const chainInfo = props.chainInfo;
  console.log('CHAININFO ->',chainInfo)

  const ftmStaking = useMatch('/ftm');
  const usdStaking = useMatch('/usd');
  

  console.log(ftmStaking,usdStaking);

  return (
    <HeaderStyle size="full" forwardedAs="header" style={{position:'fixed',zIndex:500,maxWidth:'100vw'}}>
      <HeaderLogoStyle>
        <HeaderLink href="/">
          <img src={logo} style={{height:'30px'}} />
          {/* <LidoLogo /> */}
        </HeaderLink>
      </HeaderLogoStyle>
      {
        chainInfo.vaults.map((vault) => {
          return (
            <HeaderLink key={vault} href={'/'+vault}>
              <ButtonIcon
                color="secondary"
                size="sm"
                icon={<Stake />}
                variant="ghost"
                style={{color:ftmStaking?'white':''}}
              >
                {Infos[vault]}
              </ButtonIcon>
            </HeaderLink>
          )
        })
      }
      {
        (chainInfo.name === 'Localhost'||chainInfo.name==='DogeChain') && (
          <HeaderLink key={5} href={'/dogedollar'}>
            <ButtonIcon
              color="secondary"
              size="sm"
              icon={<Wrap />}
              variant="ghost"
              style={{color:ftmStaking?'white':''}}
            >
              DogeDollar
            </ButtonIcon>
          </HeaderLink>
        )
      }
      
      {/* <HeaderLink href='/usd'>
        <ButtonIcon
          color="secondary"
          size="sm"
          icon={<Stake />}
          variant="ghost"
          style={{color:usdStaking?'white':''}}
        >
          USD Vault
        </ButtonIcon>
      </HeaderLink>
      <HeaderLink href='/hlh'>
        <ButtonIcon
          color="secondary"
          size="sm"
          icon={<Stake />}
          variant="ghost"
          style={{color:usdStaking?'white':''}}
        >
          Stake HLH
        </ButtonIcon>
      </HeaderLink>
      <HeaderLink href='/hlh-wwdoge'>
        <ButtonIcon
          color="secondary"
          size="sm"
          icon={<Stake />}
          variant="ghost"
          style={{color:usdStaking?'white':''}}
        >
          Stake HLH/WWDOGE LP
        </ButtonIcon>
      </HeaderLink> */}
      <HeaderActionsStyle>
        <HeaderWallet chainInfo={chainInfo} />
      </HeaderActionsStyle>
    </HeaderStyle>
  );
}

export default Header;
