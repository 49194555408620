
import deployments from "../deployments.json";
import dogedollar from "../dogedollar.json";
// merge the hardhat exports so all the contracts are available
// hardhat exports are of the form {chainId:[{info,contracts:{contractName:{address,abi,...}},}]}
var merged = {...deployments};
for(var chainId in dogedollar){
    for(var i=0;i<dogedollar[chainId].length;i++){
        var contracts = merged[chainId][i].contracts;
        for(var contract in dogedollar[chainId][i].contracts){
            contracts[contract] = dogedollar[chainId][i].contracts[contract];
        }
        //merged[chainId][i].push(dogedollar[chainId]);
    }
}



export default merged;