import "./index.css";

import { ChainId,DAppProvider, Mainnet, Fantom, DEFAULT_SUPPORTED_CHAINS } from "@usedapp/core";
import React from "react";
import ReactDOM from "react-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'


import App from "./App";

import { deployments } from "@my-app/contracts";

import { ThemeProvider,themeDark } from '@lidofinance/lido-ui'

import BackgroundGradient from "./components/BackgroundGradient";
import * as lidoui from '@lidofinance/lido-ui'

import {GlobalStyle} from "./components";
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";

window.lidoui = lidoui;
library.add(fab, faCheckSquare, faCoffee);

//console.log(deployments[window.nativeChain][0].contracts.Multicall.address,ChainId.Localhost)
// Change this to your own Infura project id: https://infura.io/register
const INFURA_PROJECT_ID = "defba93b47f748f09fcead8282b9e58e";


// alert(ChainId.Fantom)
ChainId.DogeChain = 2000;

const DogeChain= {
  chainId: 2000,
  chainName: "DogeChain",
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: "0x904a58dbfCC608209B124B8cc368A44Edb21dD18",
  getExplorerAddressLink: (address) =>
    `https://explorer.dogechain.dog/address/${address}`,
  getExplorerTransactionLink: (transactionHash) =>
    `https://explorer.dogechain.dog/tx/${transactionHash}`,
}

// var config = {
//  // readOnlyChainId: Mainnet.chainId,
//   readOnlyChainId: window.nativeChain,
//   readOnlyUrls: {
//     // [ChainId.DogeChain] : "https://rpc.dogechain.dog",
//     [ChainId.Localhost]: "http://localhost:8545",

//   },
//   multicallAddresses: {
//     [ChainId.Localhost]: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',//deployments[window.nativeChain][0].contracts.Multicall.address, 
//     //deployments[window.nativeChain][0].contracts.Multicall.address
//   },
//   networks: [...DEFAULT_SUPPORTED_CHAINS,DogeChain]
// }
var config = {
   readOnlyChainId: window.nativeChain,
   readOnlyUrls: {
     [ChainId.Localhost]: "http://localhost:8545",
     [ChainId.DogeChain] : "https://rpc.dogechain.dog",
     [ChainId.Polygon] : "https://polygon.llamarpc.com",
   },
   multicallAddresses: {
     [ChainId.Localhost]: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
   },
   networks: [...DEFAULT_SUPPORTED_CHAINS,DogeChain]
 }
if(window.live){
  config = {
    readOnlyChainId: window.nativeChain,
    readOnlyUrls: {
      [ChainId.DogeChain] : "https://rpc.dogechain.dog",
      [ChainId.Polygon] : "https://polygon.llamarpc.com",
    },
    networks: [...DEFAULT_SUPPORTED_CHAINS,DogeChain]
  }
}


// You should replace this url with your own and put it into a .env file
// See all subgraphs: https://thegraph.com/explorer/

ReactDOM.render(
  <HashRouter>
    <React.StrictMode>
      <DAppProvider config={config}>
        <ThemeProvider theme={themeDark}>
          <GlobalStyle />
            <BackgroundGradient width={1560} height={784} />
          <App />
        </ThemeProvider>
      </DAppProvider>
    </React.StrictMode>
  </HashRouter>,
  document.getElementById("root"),
);
